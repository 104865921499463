<template>
  <div>
    <h1 class="mt-4 mb-4">Bienvenid@ {{ perfil.usuario }}</h1>
    <p>Gracias por registrarte en el servidor, tu usuario ya está creado y activado. Te hemos enviado un correo electrónico, si no lo has recibido, mira en la carpeta de correo no deseado SPAM.</p>

    <div class="container-fluid mt-4">
      <div class="row">
        <div class="col-md card m-3 text-center shadow bg-light">
          <div class="card-body">
            <h5><i class="fas fa-wifi"></i> Antes de nada</h5>
            <hr>
            <p>Con tu cuenta te puedes conectar desde todos los dispositivos que quieras, no hay límite.</p>
            <img src="../assets/images/devices.png" class="card-img-top img-fluid m-4" style="max-width: 150px">
            <p>La única restricción es que sólo puede haber <strong>2</strong> dispositivos conectados a la vez.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md card m-3 text-center shadow bg-light">
          <div class="card-body">
            <h5 class="card-title"><i class="fas fa-money-bill-wave"></i> La pasta gansa</h5>
            <hr>
            <p>El servidor funciona gracias a las contribuciones de los usuarios, que se usan para pagar la electricidad y los costes de mantenimiento (internet, discos duros, etc.). No obtenemos ningún tipo de beneficio de las contribuciones, todo se invierte en el servidor.</p>
            <img src="../assets/images/donation.png" class="card-img-top img-fluid m-4" style="max-width: 150px">
            <p>
              Dicho lo cual, la contribución es de 10 € al año. La puedes hacer por Bizum o transferencia: (Bizum es más rápido y cómodo)
            </p>
            <button type="button" @click="nota" class="btn m-1 btn-lg btn-outline-warning"><i class="fas fa-info"></i> Nota</button>

            <div class="row">
              <div class="col-md card m-3 bg-light">
                <div class="card-body">
                  <img src="../assets/images/bizum.jpg" class="card-img-top img-fluid" style="max-width: 150px">
                  <hr>
                  <div class="text-left">
                    <p><strong>Número:</strong> 613 08 28 40</p>
                    <p><strong>Importe:</strong> 10 €</p>
                    <p><strong>Concepto:</strong> {{ perfil.usuario }}</p>
                  </div>
                </div>
              </div>

              <div class="col-md card m-3 bg-light">
                <div class="card-body">
                  <img src="../assets/images/transfer.png" class="card-img-top img-fluid" style="max-width: 70px">
                  <hr>
                  <div class="text-left">
                    <p><strong>Número de cuenta:</strong> ES09 1563 2626 3632 6254 9577</p>
                    <p><strong>Beneficiario:</strong> Álvaro Fernández-Llebrez Panizzoli</p>
                    <p><strong>Importe:</strong> 10 €</p>
                    <p><strong>Concepto:</strong> {{ perfil.usuario }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md card m-3 text-center shadow bg-light">
          <div class="card-body">
            <h5 class="card-title"><i class="fas fa-question-circle"></i> Dudas</h5>
            <hr>
            <p>Esta web no es para ver los contenidos, es sólo para gestionar tu usuario y hacer peticiones.</p>
            <p>Puedes contactar por correo o enviando un WhatsApp al 613 08 28 40.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md card m-3 text-center shadow bg-light">
          <div class="card-body">
            <h5 class="card-title"><i class="fas fa-sign-in-alt"></i> Acceso</h5>
            <hr>
            <p>¡Disfruta del servidor!</p>
            <p><button onclick="window.location.href='https://bemag.app'" class="btn m-1 btn-success">bemag.app</button></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Swal from 'sweetalert2'

  export default {
    computed: {
      perfil() {
        return this.$store.state.perfil
      }
    },
    methods: {
      nota() {
        Swal.fire({
          title: 'Nota',
          text: 'El concepto tiene que ser tu nombre de usuario, sino no se procesará el abono.',
          icon: 'info',
        })
      }
    },
    mounted() {
      this.$store.commit('setPanelLayout')
    }
  }
</script>
